import React, { Component } from 'react';
import logo from './BCW.png';
import './main-page.css';
import Header from './header';
import FeaturedCourse from './featured-course';
import CourseFilter from './course-filter'
import CourseList from './course-list'
import {CardGroup} from 'react-bootstrap';
import {Navbar} from 'react-bootstrap';



class App extends Component {
  state = {}

  componentDidMount() {
    this.fetchCourses();
  }

  fetchCourses = () => {
    fetch('/product.json')
      .then(rsp => rsp.json())
      .then(allCourses => {
        this.allCourses = allCourses;
        this.getFeatureCourse();
        this.getUniqueCategories();
        this.getListCourse();
        this.filteredCourses();
      })
  }

  getFeatureCourse = () => {
    if (this.allCourses) {
      const randomIndex = Math.floor(Math.random() * this.allCourses.length)
      const featuredCourse = [this.allCourses[randomIndex]];
      this.setState({ featuredCourse });
    };
  }

  getListCourse = () => {
    if (this.allCourses) {
      const courseList = this.allCourses;
      this.setState({ courseList });
    };
  }

  getUniqueCategories = () => {
    const filtersCategories = this.allCourses
      ? Array.from(new Set(this.allCourses.map(c => c.category)))
      :[]
      filtersCategories.unshift(null)
      this.setState({filtersCategories})
  }

   filteredCourses = (category) => {
    if (category === "" || category === undefined)
      {var vfilteredCourses = this.allCourses;}
    else
      {var vfilteredCourses = this.allCourses.filter((c)=> c.category === category);}
    
    const  filteredCourses = vfilteredCourses
    this.setState({filteredCourses});
    this.setState({ category })
   } 
  

  render() {
    return (
      <body bg="light">
            <Navbar bg="light" variant="dark"  expand="lg">
            <Navbar.Brand href="#home">
              <img
                alt=""
                img src={logo}
                width="150"
                height="100"
                className="d-inline-block align-top"
              />{' '}
              
            </Navbar.Brand>
          </Navbar>
      <div className="container" >
          
         <CourseFilter categories={this.state.filtersCategories} filteredCourses = {this.filteredCourses}/>
         <CourseList course={this.state.filteredCourses} /> 
      </div>
      </body>
    );
  }
}

export default App;
