import React, { Component } from 'react';
class CourseFilter extends Component{
    state = {}
    onSearchChange = (e) =>{
        const category = e.target.value;
        this.props.filteredCourses(category)
    }

    render() {
        const search = this.state.search;
        const categories = this.props.categories || [];
        
        return(
            <div className = "form-group row mt-3" style={{paddingTop: '20px'}}>
               <div className="col-sm-3">
                Search for course by category:    
               </div>     

                <div className="col-md-6">
                 <select className="form-control" value={search} onChange={this.onSearchChange}>
                     {categories.map((c) => <option key={c} value={c}>{c}</option>)}
                 </select>
                </div>
            </div>
        );
    }
 
}
export default CourseFilter;
