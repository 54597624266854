import React, { Component } from "react";
import "./course.css";
import { Card, Button, CardDeck,Carousel, CardGroup, CardColumns } from "react-bootstrap";

class course extends Component {
  state = {};
  render() {

     
        
    const renderFeaturedCard = (course) => {
      return (

          
          <Carousel.Item   key={course.id}>
            <span>
            <img href={course.url}
              className="d-block w-100"
              src={course.image_url}
              alt={course.name}
            />
            </span>
            <Carousel.Caption className="capcolor">
              <h3 className="capcolor">{course.name}</h3>
              <p>{course.description}</p>
              <Button variant="primary" href={course.url}>
             Go to Course{" "}
           </Button>
            </Carousel.Caption>
          </Carousel.Item>
       
      );
    };

    const renderCard = (course) => {
     return (
       <Card style={{ width: "18rem" }} key={course.id}>
         <Card.Img variant="top" src={course.image_url} />
         <Card.Body>
           <Card.Title>{course.name}</Card.Title>
           <Card.Text>{course.description}</Card.Text>
           <Button variant="primary" href={course.url}>
             Go to Course{" "}
           </Button>
         </Card.Body>
       </Card>
     );
   };

    return (
      <div className="App" >
        <div className="center">
        <Carousel>
        {this.props.course.filter(function(course) {return course.featured == 'Y';}).map(renderFeaturedCard)}   
        </Carousel>
        </div>
        <span>&nbsp;&nbsp;</span>
        <CardColumns>{this.props.course.filter(function(course) {return course.featured == 'N';}).map(renderCard)}</CardColumns>
      </div>
    );
  }
}

export default course;
