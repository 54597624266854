import React from 'react';
import Course from '../course';

const  CourseList = (props) => {
   if (props.course) 
    return (  
        <div>
            
            <Course course={props.course}/>
        </div>)

   return (<div>No Featured course avialiable</div>)     
    
}
 
export default CourseList;